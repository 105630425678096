import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Background from "../images/Bg6.png"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

class ServicePageWebDevTemplate extends React.Component {
    render() {
        const webdevpage = this.props.data.contentfulWebdevelopment
        let title = webdevpage.cityName + " Web Development | Professional Website Development"
        let keywords = [webdevpage.cityName + " Web Development", webdevpage.cityName + " Website Development", webdevpage.cityName + " Custom Website", webdevpage.cityName + " Website", webdevpage.cityName + " Website Builder"]
        let location = "/" + webdevpage.slug
        let description = "Pyxel Development offers professional " + webdevpage.cityName + " Custom Website Development services that are guaranteed to boost your business to the next level at an affordable rate."
        let isProduct = true
        let productName = webdevpage.cityName + " Web Development"
        let sku = "10000005"
        return (
            <Layout>
                <Seo title={title} keywords={keywords} location={location} description={description} isProduct={isProduct} productName={productName} sku={sku} />
                <div className="bg-no-repeat bg-cover bg-left"
                    style={{
                        backgroundImage: `url(${Background})`,
                        width: '100%',
                        height: '100%',
                        backgroundAttachment: 'absolute',
                    }}>
                    <div className="container grid gap-x-5 lg:grid-cols-2 text-white font-bold p-10 pt-36 mx-auto xl:py-40">
                        <div className="flex justify-center lg:justify-end">
                            <div>
                                <p>SERVICE</p>
                                <h1 className="text-5xl md:text-6xl xl:text-7xl font-pixeboy" style={{ lineHeight: "80%" }}>
                                    {webdevpage.cityName}<br />{webdevpage.serviceType}
                                </h1>
                                <h2 className="uppercase text-md md:text-xl max-w-lg mt-7">
                                    CUSTOM WEBSITE & WEB APPLICATION DEVELOPMENT CATERED TO YOUR BUSINESS NEEDS
                                </h2>
                                <div className="max-w-xl font-normal text-xs md:text-base">
                                    <p className="mt-7 md:mt-12">
                                        Custom website development is a challenging task that requires in-depth knowledge, planning, and practice to produce the highest quality solutions.
                                        Luckily for you, our {webdevpage.cityName} website development team can take the stress and hassle off your hands and guarantee a product that you will love.<br /><br />
                                        From front-end web designs to back-end behind the scenes management, our development team has you covered. We specialize in creating the most responsive and industry
                                        leading websites & web applications using the latest and highest standard of technology, leaving you with a website that will outperform your competition.
                                    </p>
                                    <div className="hidden lg:block mt-10 md:mt-12 xl:mt-28">
                                        <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                            <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                                <span>Request Quote</span>
                                            </button>
                                        </Link>
                                        <div className="hover:text-gray-600 mt-4">
                                            <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                                Call Us: (916) 821-9044
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center pt-10 lg:pt-0 mx-auto max-w-xs md:max-w-md lg:max-w-xl">
                            <StaticImage src="../images/web-development-process.png" placeholder="none" title={webdevpage.cityName + " web development agency"} alt={webdevpage.cityName + " web development agency"} />
                        </div>
                        <div className="lg:hidden mx-auto mt-10 md:mt-12 xl:mt-28">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="relative h-10 px-6 text-white transition-colors bg-transparent rounded-lg border border-white focus:shadow-outline hover:bg-white hover:text-gray-600">
                                    <span>Request Quote</span>
                                </button>
                            </Link>
                            <div className="hover:text-gray-600 mt-4">
                                <a className="font-normal mt-1 text-sm" href="tel:916-821-9044" title="Call Pyxel Development" alt="Call Pyxel Development">
                                    Call Us: (916) 821-9044
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-banner">
                    <div className="py-10 md:py-24">
                        <div className="text-white px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase mx-auto text-2xl font-bold md:text-4xl">
                                    ONE STOP SOLUTION
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />Web development refers to the entire process of building a website from A to Z. With our {webdevpage.cityName} web development services, we combine the process of website design,
                                    website maintenance, website publishing, web programming, and managing into a single service that you can count on.<br /><br />
                                    Our {webdevpage.cityName} Website Development team specializes in a variety of different industry leading technologies and tools. Whether you need front-end UI/UX development, back-end server side work,
                                    database management, or a combination of everything, our web development team can provide you with the highest quality products to help you achieve your goals!
                                </p>
                            </div>
                        </div>
                        <div className="flex order-2 md:justify-end md:order-1 pt-10 mx-auto max-w-xs md:max-w-sm lg:max-w-lg">
                            <StaticImage src="../images/one-stop-solution.png" placeholder="none" title={webdevpage.cityName + " Web Development company"} alt={webdevpage.cityName + " Web Development company"} />
                        </div>
                    </div>
                </div>

                <div className="bg-white">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase mx-auto text-2xl font-bold md:text-4xl">
                                    WEB DEVELOPMENT SOLUTIONS
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />We strive to create the highest quality websites for our clients and want to ensure you that your website will be responsive, dynamic, and blazing fast by implementing
                                    the latest technologies and practices in the web development industry.<br /><br />
                                    We take pride in our ability to increase efficiency and site performance by minimizing the need for outdated frameworks and methods and by incorporating the following technologies:
                                </p>
                            </div>
                        </div>
                        <div className="container mx-auto px-10 lg:px-20 pt-10 grid grid-cols-2 gap-10 md:grid-cols-3 text-center text-xs">
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/react-logo.png" placeholder="none" className="w-10" title="React JS" alt="React JS" />
                                <h3 className="pt-2 font-bold lg:text-sm">React JS</h3>
                                <p>
                                    Library used for building custom high-end user interfaces
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/gatsby-logo.png" placeholder="none" className="w-10" title="Gatsby JS" alt="Gatsby JS" />
                                <h3 className="pt-2 font-bold lg:text-sm">Gatsby</h3>
                                <p>
                                    High performance, scalable, and secure React based framework
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/tailwind-logo.png" placeholder="none" className="w-10" title="Taildwind CSS" alt="Taildwind CSS" />
                                <h3 className="pt-2 font-bold lg:text-sm">Tailwind CSS</h3>
                                <p>
                                    Utility-first CSS framework for customizable user interfaces
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/contentful-logo.png" placeholder="none" className="w-10" title="Contentful" alt="Contentful" />
                                <h3 className="pt-2 font-bold lg:text-sm">Contentful</h3>
                                <p>
                                    API-first content management platform to simplify updating your website
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/javascript-logo.png" placeholder="none" className="w-10" title="JavaScript" alt="JavaScript" />
                                <h3 className="pt-2 font-bold lg:text-sm">JavaScript</h3>
                                <p>
                                    Core programming language used in React based web programming
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/html5-logo.png" placeholder="none" className="w-10" title="HTML" alt="HTML" />
                                <h3 className="pt-2 font-bold lg:text-sm">HTML</h3>
                                <p>
                                    Markup language used to design and build pages for display
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-offblue">
                    <div className="py-10 md:py-24">
                        <div className="px-5 text-center">
                            <div className="mx-auto max-w-5xl">
                                <h2 className="uppercase mx-auto text-2xl text-white font-bold md:text-4xl">
                                    MORE WEBSITE OPTIONS
                                </h2>
                                <p className="text-xs mx-auto lg:text-sm 2xl:text-base">
                                    <br />With every different category of business, each one benefits most from different styles of websites, which is why we provide a diverse variety of web development options to fit
                                    YOUR needs. Browse some of the most popular options that we offer below. If you have a preferred platform that is not shown, don’t hesitate to ask us about it. Whether you need an e-commerce
                                    website, personal website, or integration services, we do it all!
                                </p>
                            </div>
                        </div>
                        <div className="container mx-auto px-10 lg:px-20 pt-10 grid grid-cols-2 gap-10 md:grid-cols-3 text-center text-xs">
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/react-logo.png" placeholder="none" className="w-10" title="React & Gatsby" alt="React & Gatsby" />
                                <h3 className="pt-2 font-bold lg:text-sm">React/Gatsby</h3>
                                <p>
                                    Highest tier website offering fastest load speeds, dynamic, 100% optimized for all screen sizes
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/wordpress-logo.png" placeholder="none" className="w-10" title="WordPress" alt="WordPress" />
                                <h3 className="pt-2 font-bold lg:text-sm">WordPress</h3>
                                <p>
                                    Content management system used for creating websites, blogs, portfolios, etc.
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/editorx-logo.png" placeholder="none" className="w-10" title="EditorX" alt="EditorX" />
                                <h3 className="pt-2 font-bold lg:text-sm">EditorX</h3>
                                <p>
                                    Advanced responsive web creation platform for desingers and agencies
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/wix-logo.png" placeholder="none" className="w-10" title="Wix" alt="Wix" />
                                <h3 className="pt-2 font-bold lg:text-sm">Wix</h3>
                                <p>
                                    Cloud-based web development service for creating HTML websites
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/shopify-logo.png" placeholder="none" className="w-10" title="Shopify" alt="Shopify" />
                                <h3 className="pt-2 font-bold lg:text-sm">Shopify</h3>
                                <p>
                                    Leading platform for creating e-commerce stores & websites
                                </p>
                            </div>
                            <div className="mx-auto max-w-xs">
                                <StaticImage src="../images/squarespace-logo.png" placeholder="none" className="w-10" title="Squarespace" alt="Squarespace" />
                                <h3 className="pt-2 font-bold lg:text-sm">Squarespace</h3>
                                <p>
                                    Software used for website building & hosting
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center ">
                            <Link to="/request-quote" title="Request a Free Quote" alt="Request a Free Quote">
                                <button className="m-5 relative h-10 px-6 text-xs text-black transition-colors bg-transparent rounded-lg border border-black focus:shadow-outline hover:bg-white md:mt-20">
                                    <span>Get Started <b>TODAY!</b></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default ServicePageWebDevTemplate

export const pageQuery = graphql`
query ContentfulWebdevelopmentBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulWebdevelopment( slug: {eq: $slug }) {
        title
        cityName
        boldName
        serviceType
        slug
    }
}
`